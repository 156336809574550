<template>
  <AppBase v-if="authenticated" :items="items" />
  <Authentication v-else />
</template>

<script>
import { LoginBff as Authentication } from "ngt-frontend-core";
import { AppBase, memory, usersBff } from "ngt-frontend-core";
import dayjs from "dayjs";
const { apiOpsBff } = require("ngt-frontend-core");
const { callBffAPI } = apiOpsBff;

export default {
  name: "App",
  components: {
    AppBase,
    Authentication,
  },
  data: () => {
    return {
      existingTags: [],
      items: [],
      timers: {},
      timerId: undefined,
    };
  },
  created() {
    // console.log("App created")
    // const color = "error"
    // const timeout = 10000
    // const link = ""
    // const linkLabel = ""
    // const multiLine = false
    // this.$store.state.snackbar = { text: "report_created_success", color, timeout, open: true, multiLine, link, linkLabel }

    if (this.authenticated) {
      // set global timer handling
      this.$EventBus.$on("timerAttach", (callbackEvent, interval = 0) => {
        this.timers[callbackEvent] = this.timers[callbackEvent] || {};
        this.timers[callbackEvent].interval = interval;
        this.timers[callbackEvent].called = new Date().getTime();
      });
      this.timerId = window.requestAnimationFrame(this.executeTimedEvents);
    }

    this.$EventBus.$on("checkJob", async (jobId, interval = 0) => {
      console.log("event: checkJob");
      const result = await this.checkJob(jobId);

      this.$EventBus.$emit(
        "report_created_result",
        result.jobId,
        result.status
      );
      console.log("jobId: ", jobId, "status: ", result.status);

      if (
        (result.status == "report_created_active") |
        (result.status == "report_created_pending")
      ) {
        await this.sleep(interval);
        this.$EventBus.$emit("checkJob", jobId, interval);
        return;
      }

      if (result.status == "report_created_success") {
        // const resultReport = await callBffAPI({ url: `/reports?filter=jobId:eq:${jobId}&fields=reportId`, method: "GET" })
        // const reportId = resultReport.data.length > 0 ? resultReport.data[0].reportId : null
        // const link = reportId ? `/reports/list/report/${reportId}` : ""
        const color = "success";
        const timeout = 10000;
        const link = jobId ? `/jobs/list/job/${jobId}` : "";
        const linkLabel = link
          ? this.$t("report_created_success_link_label")
          : "";
        const text = this.$t(result.status);
        const multiLine = false;
        this.$store.state.snackbar = {
          text,
          color,
          timeout,
          open: true,
          multiLine,
          link,
          linkLabel,
        };
      }

      if (
        result.status == "report_created_failed" ||
        result.status == "report_created_purged"
      ) {
        const color = "error";
        const timeout = 10000;
        const link = "";
        const linkLabel = "";
        const multiLine = false;
        this.$store.state.snackbar = {
          text: this.$t(result.status),
          color,
          timeout,
          open: true,
          multiLine,
          link,
          linkLabel,
        };
      }
    });

    this.$EventBus.$on("buildMenuItems", () => {
      this.buildMenuItems();
      this.$store.commit("page", this.$route.meta.title());
    });

    // console.log("init start")
    this.init();
    // console.log("init end")
  },
  beforeDestroy() {
    window.cancelAnimationFrame(this.timerId);
    this.timerId = undefined;
    this.$EventBus.$off();
  },
  computed: {
    authenticated() {
      // this.buildMenuItems();
      return this.$store.state.user;
    },
  },
  watch: {
    $route(to, from) {
      // re-update local settings to match with user default locale
      const locale = this.getDefaultLocale();
      const short_locale = locale.substring(0, 2);
      // console.log("App.vue - SET LOCALE: ", locale, short_locale)

      this.$i18n.locale = short_locale;
      dayjs.locale(short_locale);

      const timezone = this.getDefaultTimeZone();
      // console.log("App.vue - SET TIMEZONE: ", timezone)
      dayjs.tz.setDefault(timezone);

      // refresh available menu points
      // this.buildMenuItems();

      if (typeof to.meta.title === "function") {
        this.$store.commit("page", to.meta.title());
      }

      if (from.name === "Report Details") {
        // console.log("clean up DOM")
        let googleMapScriptTags = [
          ...document.head.getElementsByTagName("script"),
        ].filter(
          (p) =>
            p.getAttribute("src") &&
            (p.getAttribute("src").startsWith("https://maps.google.com/") ||
              p.getAttribute("src").startsWith("https://maps.googleapis.com/"))
        );
        // console.log(googleMapScriptTags)
        for (let googleMapScriptTag of googleMapScriptTags) {
          document.head.removeChild(googleMapScriptTag);
        }
        let googleMapScriptTagsInBody = [
          ...document.body.getElementsByTagName("script"),
        ].filter(
          (p) =>
            p.textContent &&
            (p.textContent.indexOf("jasperreports") !== -1 ||
              p.textContent.indexOf("google.maps.Map") !== -1)
        );
        // console.log(googleMapScriptTagsInBody)
        for (let googleMapScriptTag of googleMapScriptTagsInBody) {
          googleMapScriptTag.parentNode.removeChild(googleMapScriptTag);
        }
      }
    },
  },
  methods: {
    async init() {
      // console.log("App init")

      // detect default language
      const browserLanguage = usersBff.getBrowserLanguage();
      const storedLocale = await memory.getItemAsync("locale");
      const locale = storedLocale || browserLanguage || "en";
      // i18n.locale = locale;

      await apiOpsBff.setI18nLanguage(locale);
      this.$EventBus.$emit("buildMenuItems");

      dayjs.locale(
        locale && locale.length === 2
          ? `${locale}_${locale.toUpperCase()}`
          : locale
      );
    },
    executeTimedEvents() {
      const currTime = new Date().getTime();
      for (const item in this.timers) {
        if (currTime - this.timers[item].called > this.timers[item].interval) {
          delete this.timers[item];
          this.$EventBus.$emit(item);
        }
      }
      this.timerId = window.requestAnimationFrame(this.executeTimedEvents);
    },
    checkRootGroup() {
      return new Promise((resolve) => {
        if (!this.$store.state?.user?.group) {
          resolve(false);
        }
        const groupIds = this.$store.state.user.group.map((item) =>
          item._uri.split("/").pop()
        );
        if (groupIds?.length) {
          callBffAPI({
            url: `/groups?filter=groupId:in:[${groupIds.join(
              ","
            )}]&fields=groupToken`,
            method: "GET",
          })
            .then(({ data }) => {
              if (data?.length) {
                resolve(data.some((item) => item?.groupToken?.length === 4));
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              console.log(error);
              resolve(false);
            });
        }
      });
    },
    async buildMenuItems() {
      // console.log("buildMenuItems")
      const noLicense = [
        {
          title: this.$t("no_license"),
          action: "NoLicense",
        },
      ];
      const standard = [
        {
          title: this.$t("dashboard"),
          action: "Home",
        },
        {
          title: this.$t("reports_list"),
          action: "Reports List",
        },
        {
          title: this.$t("reports_plan"),
          action: "Reports Plan",
        },
        {
          title: this.$t("report_log"),
          action: "Report Log",
        },
      ];
      const manager = [
        {
          title: this.$t("group_manager"),
          action: "Group Manager",
        },
      ];
      const admin = [
        {
          title: this.$t("manage_datasources"),
          action: "Manage Datasources",
        },
        {
          title: this.$t("manage_files"),
          action: "Manage Files",
        },
        {
          title: this.$t("manage_notifications"),
          action: "Manage Notifications",
        },
        {
          title: this.$t("manage_templates"),
          action: "Manage Templates",
        },
        {
          title: this.$t("collection_manager"),
          action: "Collection Manager",
        },
      ];

      const isManager = await this.checkRootGroup();

      let items = noLicense;

      if (this.authenticated) {
        items = standard;
      }

      if (this.authenticated && this.authenticated.ownedBy) {
        let response = [...standard];
        if (isManager) {
          response = [...response, ...manager];
        }
        if (this.authenticated.ownedBy.root) {
          response = [...response, ...admin];
        }
        items = response;
      }

      this.items = items;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

$white: #ffffff;
$black: #000000;
$lightgreen: #9ad2ce;
$accent: #8bd1cc;
$darkgreen: #6aa19d;
$gray1: #f0f3f5;
$gray2: #5d6168;
$gray3: #696a6f;
$gray4: #e5e5e5;
$red: #d26464;

$mBlue: #49cc90;
$mGreen: #61affe;
$mRed: #f93e3e;
$mLightGreen: #50e3c2;
$mOrange: #fca130;

main {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 20px !important;
}
.main-view {
  position: relative;
  height: auto;
}
.v-menu__content {
  background-color: $white;
}

/* drawer menu bottom logos */
.v-navigation-drawer__append {
  .v-list {
    .v-list-item {
      .container {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
        padding-left: 0 !important;
        padding-right: 0 !important;

        .row {
          flex-direction: column;
          .col {
            max-width: 90%;
            padding-bottom: 0 !important;
          }
          .col:nth-child(1) {
            .v-image__image {
              background-image: url("assets/text-logo.png") !important;
              background-size: contain;
            }
          }
          .col:nth-child(2) {
            .v-responsive__sizer {
              padding-bottom: 20px !important;
            }
            .v-image__image {
              background-size: contain;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

.scrollLimit {
  max-height: 300px;
  overflow-y: scroll;
}
.dataSelector {
  // max-width: 300px;
  width: 96%;
  margin: 0 auto;

  .topTitle {
    border-bottom: 1px solid #afafaf;
  }

  .v-list-item__subtitle {
    max-width: 274px;
  }

  .bottom-search-for-selector {
    border-top: 1px solid #afafaf;

    label,
    .v-icon {
      opacity: 0.5;
    }
  }

  .top-search-for-selector {
    border-bottom: 1px solid #afafaf;

    label,
    .v-icon {
      opacity: 0.5;
    }
  }
}

.v-application {
  .pa-0 {
    padding: 0 !important;
  }
}
.pa-0 {
  padding: 0 !important;
}
.more-space {
  white-space: normal !important;
  max-height: 116px !important;
}
.dataSelector {
  .v-list-item {
    .v-list-item__title {
      color: rgba(0, 0, 0, 1) !important;
    }
  }
}
.fa-square::before {
  color: rgba(0, 0, 0, 0.6) !important;
}
.fa-check-square::before {
  color: #0090d4 !important;
}
.close-button {
  color: white !important;
  background-color: #696a6f !important;
}
</style>

<style lang="scss" scoped>
.border-top {
  border-top: 1px solid #afafaf;
}
.border-bottom {
  border-bottom: 1px solid #afafaf;
}
</style>
