import Vue from "vue";
import Vuex from "vuex";
import { mapMutations, memory } from "ngt-frontend-core";
import { getField, updateField } from "vuex-map-fields";

// try to get saved application settings from localStorage or set a default one
const appSettings = {};
Object.assign(appSettings, {
  gdprCookie: false,
  dashboard: {
    lastGenerated: 15,
    upcoming: 15,
  },
  reports: {
    limit: {},
  },
  tasks: {
    limit: {},
  },
  jobs: {
    limit: {},
  },
  datasources: {
    limit: {},
  },
  templates: {
    limit: {},
  },
  notifications: {
    limit: {},
  },
  files: {
    limit: {},
  },
  jobdetails: {
    limit: {},
  },
  datasets: {
    limit: {},
  },
  groupAssets: {
    limit: {},
  },
  groupDrivers: {
    limit: {},
  },
  groupIdentities: {
    limit: {},
  },
});
memory.getItem(
  process.env.VUE_APP_SETTINGS_STORE_VARIABLE,
  (item) => {
    Object.assign(appSettings, item);
  }
  // function() {
  //   Object.assign(appSettings, {
  //     gdprCookie: false,
  //     dashboard: {
  //       lastGenerated: 15,
  //       upcoming: 15
  //     },
  //     reports: {
  //       limit: 10
  //     },
  //     scheduled: {
  //       limit: 10
  //     },
  //     datasources: {
  //       limit: 10
  //     },
  //     templates: {
  //       limit: 10
  //     },
  //     notifications: {
  //       limit: 10
  //     }
  //   });
  // }
);

Vue.use(Vuex);

const state = {
  loading: false,
  user: false,
  tags: false,
  hide_toolbar: false,
  open_settings_dialog: false,
  canInstallPWA: false,
  page: "",
  pageSub: "",
  drawer: {
    open: false,
    clipped: true,
    mobileBreakPoint: 15000,
    fixed: false,
    permanent: false,
    mini: true,
  },
  tagCollection: {
    template: {},
  },
  APIdata: {}, // for API response cache
  appSettings,
  /*** IMPORTANT BASE CONFIG ***/
  // base Report Manager API URL
  report_manager_api: process.env.VUE_APP_REPORT_MANAGER_API,
  // API URL for idp registration
  idp_reg_url: process.env.VUE_APP_IDP_REGISTRATION,
  // API URL for forgotten password
  idp_pwd_url: process.env.VUE_APP_IDP_PASSWORD_RESET,
  // API URL for Login Manager
  idp_loginMan_url: process.env.VUE_APP_IDP_LOGIN_MANAGER,
  /*** END OF IMPORTANT BASE CONFIG ***/
};

export default new Vuex.Store({
  state,
  mutations: {
    updateField,
    ...mapMutations(state),
    device(state, setVal) {
      state.device = { ...state.device, ...setVal };
    },
    drawer(state, setVal) {
      state.drawer = { ...state.drawer, ...setVal };
    },
    setAppSetting(state, valueObject) {
      if (Object.prototype.hasOwnProperty.call(valueObject, "parent")) {
        state.appSettings[valueObject.parent][valueObject.key] =
          valueObject.value;
      } else {
        state.appSettings[valueObject.key] = valueObject.value;
      }
      memory.setItem(
        process.env.VUE_APP_SETTINGS_STORE_VARIABLE,
        state.appSettings
      );
    },
  },
  actions: {},
  getters: {
    pageTitle: (state) => () => {
      let v = state.page;
      if (Array.isArray(state.page) && Array.isArray(state.pageSub)) {
        v = state.page.concat(state.pageSub);
      }
      return v;
    },
    getField,
    getTags: (state) => () => state.tags,
  },
  modules: {},
});
