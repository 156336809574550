import Vue from "vue";
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
import { i18n } from "@/locales/i18n";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

Vue.filter("empty", function (value) {
  if (value === null || typeof value === "undefined" || value === "") {
    return "-";
  }
  return value;
});

Vue.filter("getDisplayDate", function (date, format) {
  if (!date) {
    return "";
  }
  // const timezone = "Etc/GMT+10"

  if (format === "date") {
    return dayjs(date).tz().format("L");
    // return dayjs(date).format("YYYY.MM.DD");
  }

  if (format === "estimate") {
    // return dayjs(date).fromNow();
    return dayjs(date).tz().fromNow();
    // return dayjs.tz(date, timezone).fromNow();
  }

  // return dayjs(date).format("YYYY.MM.DD HH:mm");
  // const r = dayjs.tz(date, timezone).format('L') + " " + dayjs.tz(date, timezone).format('LT')
  // const r = dayjs(date).format('L') + " " + dayjs(date).format('LT')
  const r = dayjs(date).tz().format("L") + " " + dayjs(date).tz().format("LT");
  // console.log(date, r)
  // console.log(dayjs.locale())
  return r;
});

Vue.filter("cronObject", function (item) {
  return {
    day: item.day,
    dayType: item.dayType,
    month: item.month,
    hour: item.hour,
    minute: item.minute,
  };
});

Vue.filter("shortenText", function (text, length = 280) {
  if (!text) {
    return "";
  }
  if (text.length > length) {
    return `${text.substring(0, length - 1)}...`;
  }
  return text;
});

Vue.filter("locale", function (localeId) {
  return i18n.t(localeId);
});

Vue.filter("toYesNo", function (value) {
  return value ? i18n.t("yes") : i18n.t("no");
});
