import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/locales/i18n";
import { LoginBff } from "ngt-frontend-core";
import { NoLicense } from "ngt-frontend-core";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    /* common routes */
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/Home"),
      meta: {
        title: () => i18n.t("dashboard"),
      },
    },
    {
      path: "/reports/list/",
      name: "Reports List",
      component: () => import("@/views/ReportsList"),
      meta: {
        title: () => i18n.t("reports_list"),
      },
    },
    {
      path: "/reports/list/report/:reportId",
      name: "Report Details",
      component: () => import("@/views/ReportDetails"),
      meta: {
        title: () => i18n.t("reports_details"),
      },
    },
    {
      path: "/reports/plan/",
      name: "Reports Plan",
      component: () => import("@/views/ReportsPlan"),
      meta: {
        title: () => i18n.t("reports_plan"),
      },
    },
    {
      path: "/reports/plan/report/:taskId",
      name: "Scheduled Report Details",
      component: () => import("@/views/ScheduledReportDetails"),
      meta: {
        title: () => i18n.t("scheduled_report_details"),
      },
    },
    {
      path: "/jobs/list/",
      name: "Report Log",
      component: () => import("@/views/Jobs"),
      meta: {
        title: () => i18n.t("reports_log"),
      },
    },
    {
      path: "/jobs/list/job/:jobId",
      name: "Report Log Details",
      component: () => import("@/views/JobDetails"),
      meta: {
        title: () => i18n.t("reports_log_details"),
      },
    },
    /* admin routes */
    {
      path: "/admin/datasources",
      name: "Manage Datasources",
      component: () => import("@/views/Datasources"),
      meta: {
        title: () => i18n.t("manage_datasources"),
      },
    },
    {
      path: "/admin/templates",
      name: "Manage Templates",
      component: () => import("@/views/Templates"),
      meta: {
        title: () => i18n.t("manage_templates"),
      },
    },
    {
      path: "/admin/collection/",
      name: "Collection Manager",
      component: () => import("@/views/CollectionManager"),
      meta: {
        title: () => i18n.t("collection_manager"),
      },
    },
    {
      path: "/admin/files",
      name: "Manage Files",
      component: () => import("@/views/Files"),
      meta: {
        title: () => i18n.t("manage_files"),
      },
    },
    {
      path: "/admin/notifications",
      name: "Manage Notifications",
      component: () => import("@/views/Notifications"),
      meta: {
        title: () => i18n.t("manage_notifications"),
      },
    },
    {
      path: "/admin/groups",
      name: "Group Manager",
      component: () => import("@/views/Groups"),
      meta: {
        title: () => i18n.t("group_manager"),
      },
    },
    /*service routes */
    {
      path: "/authentication",
      name: "Login Page",
      component: LoginBff,
      meta: {
        title: () => i18n.t("login"),
      },
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/views/Profile"),
      meta: {
        title: () => i18n.t("title_profile"),
      },
    },
    {
      path: "/settings",
      name: "Settings",
      component: () => import("@/views/Settings"),
      meta: {
        title: () => i18n.t("title_settings"),
      },
    },
    // {
    //   path: "/about",
    //   name: "About",
    //   component: () => import("@/views/About"),
    //   meta: {
    //     title: () => i18n.t("title_about")
    //   }
    // },
    {
      path: "/noLicense",
      name: "NoLicense",
      component: NoLicense,
      meta: {
        title: () => i18n.t("no_license"),
      },
    },
  ],
});

// Helper function to prevent duplicate navigation error
router.push_safe = (params) => {
  let from = router.currentRoute.fullPath;
  let to = router.resolve(params).route.fullPath;
  if (from === to) return;

  router.push(params);
};

export default router;
