const getLocale = () => {
  let language =
    this?.$store?.state?.user?.locale || localStorage.getItem("locale");

  if (!language || language === "null") {
    if (window.navigator.languages) {
      language = window.navigator.languages[0];
    } else {
      language = window.navigator.userLanguage || window.navigator.language;
    }
  } else {
    language = language.replace("_", "-");
  }

  if (!language.length) {
    return "en-US";
  }

  if (language.length < 5) {
    return `${language}-${language.toUpperCase()}`;
  }

  return language;
};

export default getLocale;
