import Vue from "vue";
import { i18n } from "@/locales/i18n";
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import dayjs from "dayjs";
const isEqual = require("lodash.isequal");

Vue.mixin({
  methods: {
    errorSnackbar(error) {
      this.$store.state.snackbar = {
        text: error,
        color: "error",
        timeout: 10000,
        open: true,
      };
    },

    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    isEqualObj(obj1, obj2) {
      // console.log(JSON.stringify(obj1), JSON.stringify(obj2))
      // return JSON.stringify(obj1) == JSON.stringify(obj2);
      return isEqual(obj1, obj2);
    },

    arrSortAlphabetically(arr, { direction, key } = {}) {
      if (!Array.isArray(arr)) return null;
      if (arr.length === 0) return [];

      direction = direction || "asc";
      key = key || "name";

      const isObj = typeof arr[0] === "object";
      let result = isObj
        ? arr.sort((a, b) => a[key].localeCompare(b[key]))
        : arr.sort((a, b) => a.localeCompare(b));

      if (direction === "desc") result.reverse();
      return result;
    },

    async checkJob(jobId) {
      const url = `${process.env.VUE_APP_BFF_ORIGIN}/jobs/${jobId}`;
      const method = "GET";
      try {
        const result = await callAPI({ url, method });
        let { jobId, status } = result.data;
        status = "report_created_" + status.split(".").pop();
        return { jobId, status };
      } catch (error) {
        this.$store.state.snackbar = {
          text: error,
          color: "error",
          timeout: 10000,
          open: true,
        };
      }
    },

    selectedItemsExtract(referenceParameters, indexOfQueryParameters) {
      let selectedGroups = [];
      let selectedItems = [];
      if (indexOfQueryParameters !== -1) {
        // because of compatibility, some records has the old item format
        if (
          referenceParameters.inputValues.items[indexOfQueryParameters].groups
        ) {
          selectedGroups.push(
            ...referenceParameters.inputValues.items[indexOfQueryParameters]
              .groups
          );
        }

        if (
          referenceParameters.inputValues.items[indexOfQueryParameters].items
        ) {
          selectedItems.push(
            ...referenceParameters.inputValues.items[indexOfQueryParameters]
              .items
          );
        } else {
          const queryParameters =
            referenceParameters.inputValues.queryParameters[
              indexOfQueryParameters
            ].split(".");

          queryParameters.find((queryParameter) => {
            if (
              Array.isArray(
                referenceParameters.inputValues.items[indexOfQueryParameters][
                  queryParameter
                ]
              )
            ) {
              selectedItems.push(
                ...referenceParameters.inputValues.items[
                  indexOfQueryParameters
                ][queryParameter]
              );
              return true;
            } else if (
              ["number", "string"].includes(
                typeof referenceParameters.inputValues.items[
                  indexOfQueryParameters
                ][queryParameter]
              )
            ) {
              selectedItems.push(
                referenceParameters.inputValues.items[indexOfQueryParameters]
              );
              return true;
            }
            return false;
          });
        }
      }

      return {
        items: selectedItems,
        groups: selectedGroups,
      };
    },

    selectedAssetsAndItems(referenceParameters) {
      const indexOfQueryParameters =
        referenceParameters.inputValues.queryParameters.findIndex((p) =>
          ["assetIds.assetId", "assetIds", "assetId"].includes(p)
        );

      if (indexOfQueryParameters === -1) {
        return { groups: [], items: [] };
      }

      return this.selectedItemsExtract(
        referenceParameters,
        indexOfQueryParameters
      );
    },

    selectedAssetsAndItemsExtract(referenceParameters) {
      const { groups, items } =
        this.selectedAssetsAndItems(referenceParameters);

      return {
        items: items.map((p) => this.extractTitle(p)).join(", "),
        groups: groups.map((p) => this.extractTitle(p)).join(", "),
      };
    },

    selectedDriversAndItems(referenceParameters) {
      const indexOfQueryParameters =
        referenceParameters.inputValues.queryParameters.findIndex((p) =>
          ["driverIds.driverId", "driverIds", "driverId"].includes(p)
        );

      if (indexOfQueryParameters === -1) {
        return { groups: [], items: [] };
      }

      return this.selectedItemsExtract(
        referenceParameters,
        indexOfQueryParameters
      );
    },

    selectedDriversAndItemsExtract(referenceParameters) {
      const { groups, items } =
        this.selectedDriversAndItems(referenceParameters);

      return {
        items: items.map((p) => this.extractTitle(p)).join(", "),
        groups: groups.map((p) => this.extractTitle(p)).join(", "),
      };
    },

    selectedGeoZonesAndItems(referenceParameters) {
      const indexOfQueryParameters =
        referenceParameters.inputValues.queryParameters.findIndex((p) =>
          ["geoZoneIds.geoZoneId", "geoZoneIds", "geoZoneId"].includes(p)
        );

      if (indexOfQueryParameters === -1) {
        return { groups: [], items: [] };
       }

      return this.selectedItemsExtract(
        referenceParameters,
        indexOfQueryParameters
      );
    },

    selectedGeoZonesAndItemsExtract(referenceParameters) {
      const { groups, items } =
        this.selectedGeoZonesAndItems(referenceParameters);

      return {
        items: items.map((p) => this.extractTitle(p)).join(", "),
        groups: groups.map((p) => this.extractTitle(p)).join(", "),
      };
    },

    showAssets(assets, item) {
      if (assets.length === 1 && assets[0].allAssets === true)
        return this.$t("all_assets");
      const currentAssetsList = [];
      assets.map((item) => {
        item.assetId &&
          item.meta
            .filter((v) => ["both", "ui"].includes(v.display))
            .map((v) => currentAssetsList.push(v.value));
        item.assetIds &&
          item.assetIds.map((item) => {
            item.meta
              .filter((v) => ["both", "ui"].includes(v.display))
              .map((v) => currentAssetsList.push(v.value));
          });
      });
      return currentAssetsList.join(", ");
    },

    showDrivers(drivers) {
      if (drivers.length === 1 && drivers[0].allDrivers === true)
        return this.$t("all_drivers");
      const currentDriversList = [];
      drivers.map((item) => {
        item.driverId &&
          item.meta
            .filter((v) => ["both", "ui"].includes(v.display))
            .map((v) => currentDriversList.push(v.value));
        item.driverIds &&
          item.driverIds.map((item) => {
            item.meta
              .filter((v) => ["both", "ui"].includes(v.display))
              .map((v) => currentDriversList.push(v.value));
          });
      });
      return currentDriversList.join(", ");
    },

    showGeoZones(geoZones) {
      if (geoZones.length === 1 && geoZones[0].allGeoZones === true)
        return this.$t("all_geoZones");
      const currentGeoZonesList = [];
      geoZones.map((item) => {
        item.geoZoneId &&
          item.meta
            .filter((v) => ["both", "ui"].includes(v.display))
            .map((v) => currentGeoZonesList.push(v.value));
        item.geoZoneIds &&
          item.geoZoneIds.map((item) => {
            item.meta
              .filter((v) => ["both", "ui"].includes(v.display))
              .map((v) => currentGeoZonesList.push(v.value));
          });
      });
      return currentGeoZonesList.join(", ");
    },

    /* helpers */
    async askForData(url) {
      try {
        // console.log("callAPI url: ", url)
        const result = await callAPI({ url, method: "GET", cache: true });
        // console.log("callAPI result", result)
        return result.data;
      } catch (err) {
        // console.log("callAPI error", err)
        return null;
      }
    },

    async askForDataBff(url) {
      try {
        // console.log("callAPI url: ", url)
        const result = await callBffAPI({ url, method: "GET", cache: true });
        // console.log("callAPI result", result)
        return result.data;
      } catch (err) {
        // console.log("callAPI error", err)
        return null;
      }
    },

    async getDatasourceName(datasourceId) {
      if (!datasourceId) return "";
      const url = `${process.env.VUE_APP_BFF_ORIGIN}/datasources/${datasourceId}`;
      const data = await this.askForData(url);
      if (data == null) return i18n.t("not-available");
      return data.name;
    },

    async getTemplateNamesByIds(templateIds) {
      templateIds = templateIds || [];
      if (templateIds.length === 0) return [];
      templateIds = [...new Set(templateIds)];
      const url = `/templates?filter=templateId:in:[${templateIds.join(
        ","
      )}]&fields=name`;
      let data = await this.askForDataBff(url);
      if (data == null) data = [];
      const result = {};
      data.forEach(
        (p) => (result[p.templateId] = i18n.t(`template_name.${p.name}`))
      );
      templateIds
        .filter((p) => typeof result[p] === "undefined")
        .forEach((p) => (result[p] = i18n.t("not-available")));
      return result;
    },

    async getDatasourceNamesByIds(datasourceIds) {
      datasourceIds = datasourceIds || [];
      if (datasourceIds.length === 0) return [];
      datasourceIds = [...new Set(datasourceIds)];
      const url = `/datasources?filter=datasourceId:in:[${datasourceIds.join(
        ","
      )}]&fields=datasourceId,name`;
      let data = await this.askForDataBff(url);
      if (data == null) data = [];
      const result = {};
      data.forEach((p) => (result[p.datasourceId] = p.name));
      return result;
    },

    async getDatasetNamesByIds(datasetIds) {
      datasetIds = datasetIds || [];
      if (datasetIds.length === 0) return [];
      datasetIds = [...new Set(datasetIds)];
      const url = `/datasets?filter=datasetId:in:[${datasetIds.join(
        ","
      )}]&fields=datasetId,name`;
      let data = await this.askForDataBff(url);
      if (data == null) data = [];
      const result = {};
      data.forEach((p) => (result[p.datasetId] = p.name));
      return result;
    },

    async getTemplate(templateId) {
      if (!templateId) return null;
      const url = `/templates?filter=templateId:eq:${templateId}&fields=name,description,inputParams,tags,format`;
      const data = await this.askForDataBff(url);
      if (!data || data.length !== 1) {
        return null;
      }
      return data[0];
    },

    async getTemplateName(templateId) {
      if (!templateId) return "";
      const data = await this.getTemplate(templateId);
      if (data == null) return i18n.t("not-available");
      // return data.name;
      return i18n.t(`template_name.${data.name}`);
    },

    async getUserName(url) {
      if (!url) return "";
      // url += `/attrs/name`;
      const data = await this.askForData(url);
      if (data == null) return i18n.t("not-available");
      return data.name;
    },

    emailNotificationsExtract(notificationValues, emailNotificationLocales) {
      if (notificationValues == null) return [];
      let result = [];
      const emailChannels = notificationValues.channels.filter(
        (p) => p.type === "mail"
      );
      if (emailChannels.length == 0) return "";
      const emailChannel = emailChannels[0];
      for (let item of emailChannel.items) {
        const locale = this.getLocaleByTemplateId(
          item.templateId,
          emailNotificationLocales
        );
        const inputValues = item.inputValues;
        const notificationItem = inputValues.items[0];
        const name = notificationItem.templateContext.name;
        const withAttachments =
          notificationItem.templateContext.withAttachments;
        const withAttachmentsText = withAttachments
          ? this.$t("attach-reports-description")
          : "";
        const addressTo = notificationItem.address.to.email;
        result.push({
          name,
          withAttachments,
          withAttachmentsText,
          addressTo,
          locale: this.$t(locale),
        });
      }
      // console.log("emailNotificationsExtract", result)
      return result;
    },

    getLocaleByTemplateId(templateId, emailNotificationLocales) {
      const emailNotificationLocale = emailNotificationLocales.find(
        (p) => p.templateId == templateId
      );
      return emailNotificationLocale ? emailNotificationLocale.locale : "";
    },

    async GetEmailNotificationLocales(selectedTemplateId) {
      // console.log("GetEmailNotificationLocales")
      const templateNotifications = await this.getTemplateNotifications(
        selectedTemplateId
      );
      const notificationIds = templateNotifications.map(
        (p) => p.notificationId
      );
      // console.log("notificationIds", notificationIds)
      let notifications = await this.getNotifications(notificationIds);
      notifications = notifications.filter((p) => p.channelType == "mail");
      // console.log("notifications", notifications)
      let result =
        notifications.length === 0 ? [] : notifications[0].settings.locales;
      // console.log("GetEmailNotificationLocales", result)

      result.forEach((p) => {
        p.text = i18n.t(p.locale);
      });
      // console.log(result)
      return result;
    },

    async getTemplateNotifications(selectedTemplateId) {
      if (selectedTemplateId == null) {
        return [];
      }
      try {
        this.$store.state.loading = true;
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${selectedTemplateId}/notifications`;
        const result = await callAPI({
          url,
          method: "GET",
        });
        this.$store.state.loading = false;
        return result.data;
      } catch (error) {
        console.log(error);
        this.$store.state.loading = false;
      }
    },

    async getNotifications(notificationIds) {
      if (notificationIds.length === 0) {
        return [];
      }
      try {
        this.$store.state.loading = true;
        let url = `/notifications?filter=notificationId:in:[${notificationIds.join(
          ","
        )}]`;
        const result = await callBffAPI({ url, method: "GET", cache: true });
        this.$store.state.loading = false;
        return result.data;
      } catch (error) {
        console.log(error);
        this.$store.state.loading = false;
      }
    },

    async getTask(queueId) {
      if (!queueId) {
        return null;
      }
      try {
        const result = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/queue/${queueId}`,
          method: "GET",
          cache: true,
        });
        return result.data;
      } catch {
        return this.$t("no_task_found");
      }
    },

    async getTaskIdsByQueueIds(queueIds) {
      if (!Array.isArray(queueIds) || queueIds.length == 0) return [];
      try {
        const result = await callAPI({
          url: `${
            process.env.VUE_APP_BFF_ORIGIN
          }/queue?filter=queueId:in:[${queueIds.join(",")}]&fields=taskId`,
          method: "GET",
          cache: true,
        });
        return result.data;
      } catch {
        return [];
      }
    },

    getStatusColor(status) {
      let statusTail = "";
      status.includes(".")
        ? (statusTail = status.split(".").pop())
        : (statusTail = status);
      // statusTail = "generating" testing a color
      switch (statusTail) {
        case "success":
          return "green";
        case "failed":
          return "red";
        case "purged":
        case "deleted":
        case "inactive":
          return "grey";
        case "active":
        case "processed":
          return "blue";
        case "pending":
        case "preparing":
        case "generating":
          return "orange";
        default:
          return "yellow";
      }
    },

    showReportFormat(type) {
      switch (type) {
        case "pdf":
          return "mdi-file-pdf-box";
        case "xls":
        case "xlsx":
          return "mdi-file-excel";
        default:
          return "mdi-file-code";
      }
    },

    showReportFormatColor(type) {
      switch (type) {
        case "pdf":
          return "red";
        case "xls":
        case "xlsx":
          return "green";
        default:
          return "black";
      }
    },

    createAPIStream(url, initialPage = 0, limit = 30) {
      function _stream(_url, _initialPage, _limit) {
        return new Promise((resolve) => {
          callAPI({
            url: `${_url}${
              _url.includes("?") ? "&" : "?"
            }page=${_initialPage}&limit=${_limit}`,
            method: "GET",
            cache: true,
          }).then((result) => {
            _initialPage++;
            resolve({
              data: result.data,
              next() {
                return _stream(_url, _initialPage, _limit);
              },
            });
          });
        });
      }
      return () => _stream(url, initialPage, limit);
    },

    getDefaultLocale() {
      let locale = localStorage.getItem("locale");
      if (locale == "null") locale = null;
      if (!locale && this.$store && this.$store.state.user) {
        locale = this.$store.state.user.locale;
      }
      if (!locale) {
        locale = "en_US";
      }
      return locale;
    },

    getDefaultTimeZone() {
      let timezone = localStorage.getItem("timezone");
      if (timezone == "null") timezone = null;
      const user = this.$store.state.user;
      if (!timezone && this.$store && this.$store.state.user) {
        timezone = this.$store.state.user.timezone;
      }
      if (!timezone) {
        timezone = "GMT";
      }
      return timezone;
    },

    // massDataLoader(storeHere, url, initialPage = 0, limit = 20) {
    //   const stream = this.createAPIStream(url, initialPage, limit);
    //   async function _take(_stream) {
    //     const { data, next } = await _stream();
    //     if (data.length === 0) {
    //       return;
    //     }
    //     data.forEach(item => {
    //       storeHere.push(item);
    //     });
    //     return _take(next);
    //   }
    //   return _take(stream);
    // }

    // get report file languages
    async GetReportLocales(templateId) {
      // console.log("GetReportLocales")
      const templateFiles = await this.getTemplateFiles(templateId);
      // const templateFileList = await Promise.all(
      //   templateFiles.map(p => this.getTemplateFile(p.fileId))
      // );
      // const templateFileNames = templateFileList.map(p => p.fileName);
      if (templateFiles === null || templateFiles.length === 0) {
        const err = new Error("Missing locales for report template");
        err.data = { templateId };
        throw err;
      }

      const fileIds = templateFiles.map((p) => p.fileId);
      const templateFileNames = await this.getTemplateFileNamesByFileIds(
        fileIds
      );
      const templateLanguageFileNames = templateFileNames.filter((p) =>
        this.isLanguageFileName(p)
      );
      if (
        templateLanguageFileNames === null ||
        templateLanguageFileNames.length === 0
      ) {
        const err = new Error("Missing locales for report template");
        err.data = { templateId };
        throw err;
      }

      const templateLanguages = templateLanguageFileNames.map((p) =>
        this.createLanguage(p)
      );
      return templateLanguages;
    },

    async getTemplateFiles(templateId) {
      if (!templateId) {
        return [];
      }
      const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${templateId}/files`;
      const result = await callAPI({ url, method: "GET" });
      if (result.status !== 200) {
        return [];
      }
      if (!result.data) {
        return [];
      }
      return result.data;
    },

    async getTemplateFileNamesByFileIds(fileIds) {
      const url = `/files?filter=fileId:in:[${fileIds.join(
        ","
      )}]&fields=fileName`;
      const data = await this.askForDataBff(url);
      const result = data.map((p) => p.fileName);
      return result;
    },

    async getTemplateFile(fileId) {
      if (!fileId) {
        return null;
      }
      // const url = `${process.env.VUE_APP_BFF_ORIGIN}/files/${fileId}`;
      const url = `${process.env.VUE_APP_BFF_ORIGIN}/files?filter=fileId:eq:${fileId}`;

      const result = await callAPI({ url, method: "GET" });
      if (result.status !== 200) {
        return null;
      }
      if (!result.data || result.data.length != 1) {
        return null;
      }
      return result.data[0];
    },

    isLanguageFileName(fileName) {
      return fileName.substr(0, 7) === "labels_";
    },

    createLanguage(languageFileName) {
      const language = languageFileName.substring(
        7,
        languageFileName.lastIndexOf(".")
      );
      return {
        value: language.substring(0, 5),
        text: this.$t(language.substring(0, 5)),
        legacy: language.substring(0, 2),
      };
    },

    arrLookup(arr, keyField, keyValue, valueField) {
      const item = arr.find((p) => p[keyField] === keyValue);
      if (typeof item === "undefined") return null;
      return item[valueField];
    },

    // It takes an array of normal objects
    arrToHash(arr, key) {
      key = key || "_id";
      if (arr.length == 0) return {};
      if (typeof arr[0] !== "object") return {};
      const result = {};
      arr.forEach((p) => (result[p[key]] = this.omit(p, key)));
      return result;
    },

    // Creates an array of normal objects
    hashToArr(hash, key) {
      key = key || "_id";
      const hashKeys = Object.keys(hash);
      if (hashKeys.length == 0) return [];
      if (typeof hash[hashKeys[0]] !== "object") return [];
      const result = hashKeys.map((p) => ({ [key]: p, ...hash[p] }));
      return result;
    },

    //clone object except defined keys
    omit(obj, omitKey) {
      return Object.keys(obj).reduce((result, key) => {
        if (key !== omitKey) {
          result[key] = obj[key];
        }
        return result;
      }, {});
    },

    distinct(array) {
      return [...new Set(array)];
    },

    getFormattedDate(date) {
      // return dayjs(date).tz().format('L') // no need to timezone we use this from date picker to display date and want to see the same date user picked
      return dayjs(date).format("L");
    },

    async getUsers() {
      // const result = await callBffAPI({
      //   url: `/identities?ownedBy=${this.$store.state.user.ownedBy}&idType=user&fields=identityId,name,_uri&sort=name:asc`,
      //   method: "GET"
      // });
      const result = await callBffAPI({
        url: `/identities?idType=user&fields=identityId,name,_uri&sort=name:asc`,
        method: "GET",
      });
      if (result == null) return null;
      return result.data;
    },

    async getTagsForEntity(entityName) {
      const response = await callAPI({
        url: `${process.env.VUE_APP_BFF_ORIGIN}/_abac/tag-collection/${entityName}`,
        method: "GET",
      });
      if (response == null) return null;
      return response.data.map((p) => ({ tag: p }));
    },

    sendErrorReport(err) {
      err.data.identityUri = this.$store.state.user._uri;
      console.log(err.message, err.data);
    },

    async deleteJob(jobId) {
      const url = `${process.env.VUE_APP_BFF_ORIGIN}/reports?jobId=${jobId}`;
      const result = await callAPI({ url, method: "GET" });
      if (result.data.length !== 0) return;
      return await callAPI({
        url: `${process.env.VUE_APP_BFF_ORIGIN}/jobs/${jobId}`,
        method: "DELETE",
      });
    },

    getObjValueByPath(obj, path) {
      const arr = path.split(/[.[]['"]?/);
      let o = obj;
      while (arr.length && o) {
        o = o[arr.shift().replace(/['"]?]$/, "")];
      }
      return o;
    },

    escapeRegExp(str) {
      return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
    },

    replaceAll(str, find, replace) {
      return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
    },

    replaceMustaches(str, params) {
      let result = str;
      var mustaches = result.match(/{{\s*[\w.]+\s*}}/g);
      if (mustaches) {
        for (let findKey of mustaches) {
          const valueKey = findKey.slice(2, -2);
          let value = this.getObjValueByPath(params, valueKey);
          if (value === null || typeof value === "undefined") value = "";
          result = this.replaceAll(result, findKey, value);
        }
      }
      return result;
    },

    extractDesc(item) {
      let metas = item.meta.filter((m) => ["both", "ui"].includes(m.display));
      if (metas.length < 2) return "";
      metas.shift();
      let values = metas.map((m) => m.value);
      const result = values.join(", ");
      return result;
    },

    extractTitle(item) {
      let metas = item.meta.filter((m) => ["both", "ui"].includes(m.display));
      if (metas.length === 0) return "";
      const result = metas[0].value;
      return result;
    },

    validEmail(email) {
      const regexp =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|\\[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-x7f])+)\])/;
      const result = regexp.test(email);
      // console.log("validEmail", email, result)
      return result;
    },

    getTemplateTypeItems(templates) {
      const getChildrenOfGroup = (group, templates, t) => {
        let children = [];
        let items = templates.filter((p) => p.tags.includes(group));
        for (let item of items) {
          const text1 = `template_name.${item.name}`;
          const text2 = t(text1);
          const text3 = text2 + ` (${item.format})`;

          children.push({
            type: "node",
            value: item.templateId,
            text: text3,
            desc: t(`template_desc.${item.name}`),
          });
        }
        children = this.arrSortAlphabetically(children, { key: "text" });
        return children;
      };

      let tags = templates.map((p) => p.tags);
      tags = tags.flat();
      tags = tags.filter((p) =>
        p.startsWith("role:reportManager:template:group")
      );

      const groups = this.distinct(tags);

      const t = (v) => {
        return this.$t(v);
      };

      let items = [];
      for (let [index, group] of groups.entries()) {
        const text = this.$t(group.substring(group.lastIndexOf(":") + 1));

        items.push({
          type: "group",
          value: `GROUP_${index}`,
          text,
          desc: "",
          children: getChildrenOfGroup(group, templates, t),
        });
      }
      // console.log(items)
      return items;
    },

    errorToRunImmediate(name) {
      this.$store.state.snackbar = {
        text: this.$t("run_immediately_error", [name]),
        color: "error",
        timeout: 5000,
        open: true,
      };
    },

    treeForEach(root, fn) {
      function processNodeReq(node) {
        fn(node);
        for (const child of node.children) {
          processNodeReq(child);
        }
      }
      if (Array.isArray(root)) {
        for (const child of root) {
          processNodeReq(child);
        }
        return;
      }
      processNodeReq(root);
    },

    treeFind(root, fn) {
      try {
        this.treeForEach(root, (node) => {
          if (fn(node) === true) {
            const err = new Error("FOUND");
            err.node = node;
            throw err;
          }
        });
      } catch (err) {
        if (err.message === "FOUND") return err.node;
      }
      return null;
    },
  },
});
